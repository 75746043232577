import React,{ useEffect, useRef, useState } from "react"
import gsap from 'gsap'
import { TimelineMax, Power4 } from "gsap"
import get from 'lodash/get'
import { graphql } from "gatsby"
import styled from 'styled-components'
import { getCurrentLangKey } from 'ptz-i18n';

import Layout from "../components/layout"
import HeaderSection from '../components/Home/HeaderSection/HeaderSection'
import StartAnimation from '../components/Animation/StartScreen'
import { media } from '../lib/style-utils'
import CaseSection from '../components/Home/CaseSection/CaseSection'
import ArticleSection from '../components/Home/ArticleSection/ArticleSection'
import WorkSection from '../components/Home/WorkSection/WorkSection'
import QouteSection from '../components/Home/QouteSection/QouteSection'
import Carroussel from '../components/Home/Carroussel/Carroussel'
import Indicator from '../images/indicator.svg'
import SEO from "../components/seo"

gsap.registerPlugin(TimelineMax);

const Main = styled.div`
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
`

const Container = styled.div`

`
const AnimationBlock = styled(StartAnimation)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`

const ContainerFullWidth = styled.div`
    margin: 40px auto;
    width: 100%;
    max-width: 100%;
`
const LayoutBox = styled.div`
  opacity:0;
  height: 100%;
  transform: translateY(450px);
`

const ScrollIndicatorWrap = styled.div`
    z-index: -1;
    width: 100%;
    text-align: center;
    position: fixed;
    top: calc(100vh - 8rem);
    left: 0;
    display: none !important;
    ${media.desktop`
      display: block !important;
    `}
`

const IndicatorSvg = styled.img`
    z-index: -1;
    display: none;
    margin: 0 auto;
    ${media.tablet`
    `};
    ${media.desktop`
        display: flex;
        flex-direction: column;
        width: 1.6rem;
        align-self: center;
    `};
    }
`

const Spacer = styled.div`
    padding-top: 2rem;
    ${media.tablet`
        padding-top: 6rem;
    `}
`

const IndexPage = props => {
  let rect12 = useRef(null)
  let app = useRef(null)
  const [tl] = useState(new TimelineMax({delay: 2}));

  const url = props.location.pathname;
  const { langs, defaultLangKey } = props.data.site.siteMetadata.languages;

  let langKey = getCurrentLangKey(langs, defaultLangKey, url);
  let homeLink = `/${langKey}/`

  useEffect(() => {
    // Images Vars
    const firstRect = rect12
    const LayoutDiv = document.getElementById('homescale')
    
    // Content Animation Complete
    const handlecomplete = () => {
      if(LayoutDiv) {
        LayoutDiv.style.transform = "none";
        // rect12.current.style.transform = "none";
      }
      if(app) {
          app.current.style.height = "100%";
      }
    }

    // tl.staggerTo(ContainerFirst, 1, { width: "0%", ease: Power3.easeInOut })
    // tl.to(ContainerFirst, props.time, { xPercent: 100, ease: Power3.easeInOut })
    tl.to(firstRect, 2, { y:0,opacity: 1, onComplete:handlecomplete,ease: Power4.easeInOut }, 0,)
  }, [tl])


  useEffect(() => {
    document.getElementById('indicatorbox').style.display = 'block'
    const indicator = document.getElementById('indicatorbox')
    const scrollFunction = () => {
      if (document.body.scrollTop > 200 || document.documentElement.scrollTop > 200) {
        if(indicator) {
          indicator.style.display = 'none'
        }
        
      } else {
        if(indicator) {
          indicator.style.display = 'block'
        }
      }
    }


    window.addEventListener("scroll", scrollFunction);

    return () => window.removeEventListener("scroll", scrollFunction);

  })

  const projects = get(props, 'data.case.nodes', [])
  const articles = get(props, 'data.article.nodes', [])
  const Services = get(props, 'data.services.nodes', [])
  const seoData = get(props, 'data.homepage.nodes[0].seo', [])
  const langHp = get(props, 'data.homepage.nodes[0].language', [])  
  
  let data = {
    title: seoData.title,
    description: seoData.description,
    lang: 'en',
    image: seoData.social.facebook.image && seoData.social.facebook.image.url,
    imageTwitter: seoData.social.twitter.image && seoData.social.twitter.image.url,
    robots: seoData.advanced.robots && seoData.advanced.robots
  }

  // slugs for translation
  const slugs = {
    slugNl: '',
    slugEn: '',
  }

  return (
    <Main ref={app}>
        <SEO {...data}/>
       <AnimationBlock time={1.85} delay={1.85} />
       <LayoutBox id="homescale" ref={el => (rect12 = el)}>
           <Layout form={true} data={props.data.site} location={props.location} slugs={slugs}>
               <Container>
                 <HeaderSection url={homeLink} langHp={langHp}/>
               </Container>
               <ContainerFullWidth>
                  <WorkSection data={Services}/>
               </ContainerFullWidth>
               <CaseSection data={projects} url={homeLink}/>
               <ArticleSection data={articles} url={homeLink} location={props.location}/>
               <QouteSection />
               <Spacer/>
               <Carroussel />
           </Layout>
       </LayoutBox>
        <ScrollIndicatorWrap>
            <IndicatorSvg id="indicatorbox" src={Indicator}/>
        </ScrollIndicatorWrap>
    </Main>
  )
}

export default IndexPage

export const query = graphql`
query {
  site{
    siteMetadata{
      languages {
        defaultLangKey
        langs
      }
    }
  }

    homepage:allCraftHomepageHomepageEntry(filter: {siteId: {eq: 1}}) {
      nodes {
        title
        language
        seo {
          title
          description
          social {
            facebook {
              image {
                url
              }
            }
            twitter {
              image {
                url
              }
            }
          }
          advanced {
            robots
          }
        }
      }
    }
    case:allCraftCaseCaseEntry(limit: 4,filter: {siteId: {eq: 1}}) {
      nodes {
        id
        uri
        slug
        title
        language
        casesubtitle
        categoriesServices {
          title
        }
        casetext1
        casecardimg {
          url
        }
      }
    }
    article:allCraftArticleArticleEntry(sort: {order: DESC, fields: postDate}, limit: 2,filter: {siteId: {eq: 1}, categoriesServices: {elemMatch: {slug: {ne: "cannes"}}}}) {
      nodes {
        id
        title
        blogintro
        blogtext1
        slug
        blogreadtime
        dateCreated
        language
        postDate
        author {
          name
        }
        blogcoverimg {
          ... on craft_superkraft_Asset {
            id
            url
          }
        }
        categoriesServices {
          title
        }
      }
    }
    services:allCraftServicesServicesEntry(filter: {siteId: {eq: 1}}) {
      nodes {
        servicesSection {
          ... on craft_servicesSection_service_BlockType {
            servicetitle
            serviceparagraph
            serviceimage {
              url
            }
          }
        }
        slug
        siteId
        language
        workServiceIntro
        WorkServicesText
        workServiceTitle
        workServiceImage {
          url
        }
      }
    }
}
`
